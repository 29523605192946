<template>		
	<div>		
		<div class="block sm:hidden mx-4">
			<div v-for="pedido in ordenes" v-bind:key="pedido.id" class="border border-gray-200 rounded mb-4 p-2 bg-white">

				<div class="flex w-full gap-2"> 
					<div v-if="fields.fuente" class="border text-primary border-primary rounded-full text-center w-auto py-1 px-2">
						<p class="whitespace-nowrap">{{pedido.ecom ? "B2C" : "B2B"}}</p>
					</div>

					<router-link :to=' getLinks(pedido).pedido ' class="w-full">
						<div :class="[getStatus(pedido).style]" class="rounded-full text-center py-1 px-2">
							<p class="whitespace-nowrap">{{ getStatus(pedido).text }}</p>
						</div>
					</router-link>
				</div>
				<div class="flex flex-row pt-2 overflow-x-scroll w-full space-x-2" v-if="!pedido.cancelado">
					<div v-if="fields.aprobar" class="basis-3/12 w-full">
						<label class="block text-gray-400 text-sm font mb-1" for="nombre">
							Aprobación
						</label>
						<div  :class="[pedido.require_aprobacion ? ( pedido.aprobado ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
							<p class="whitespace-nowrap">{{ pedido.require_aprobacion ? ( pedido.aprobado ? "Aprobado" : "Por abrobar") : "No requiere"}}</p>
						</div>
					</div>
					<div v-if="fields.surtir" class="basis-3/12 w-full">
						<label class="block text-gray-400 text-sm font mb-1" for="nombre">
							Surtido
						</label>
						<surtir :pedido="pedido" :user="props.user"></surtir>
					</div>
					<div v-if="fields.facturar" class="basis-3/12 w-full">
						<label class="block text-gray-400 text-sm font mb-1" for="nombre">
							Factura
						</label>
						<div :class="[pedido.require_factura ? ( pedido.pedido_factura.length > 0 ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
							<p class="whitespace-nowrap">{{ pedido.require_factura ? ( pedido.pedido_factura.length > 0 ? "Facturado" : "Por subir") : "No requiere"}}</p>
						</div>
					</div>
					<div v-if="fields.entregar" class="basis-3/12 w-full">
						<label class="block text-gray-400 text-sm font mb-1" for="nombre">
							Entrega
						</label>
						<entregar :pedido="pedido"></entregar>						
					</div>
					<div v-if="fields.pago && pedido.proveedor.payments" class="basis-3/12 w-full">
						<label class="block text-gray-400 text-sm font mb-1" for="nombre">
							Pago
						</label>
						<div :class="[ pedido.require_factura ? ( pedido.pagado ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
							<p class="whitespace-nowrap">{{ pedido.require_factura ? ( pedido.pagado ? "Pagado" : "Pendiente") : "No requiere"}}</p>
						</div>
					</div>
				</div>
				<div class="w-full mt-2">
					<div class="flex flex-row  justify-between">											
						<router-link :to=' getLinks(pedido).pedido ' class="underline cursor-pointer">
							{{pedido.id}}
						</router-link>
						<div v-if="!fields.cliente && !fields.proveedor" class="whitespace-nowrap flex-row flex">
							{{(user == 'admin') ? formatTime(pedido) : (pedido.created_at ? (new Date(pedido.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' }) : "") }}
							<div @click="showPedido[pedido.id] == null ? showPedido[pedido.id] = true : showPedido[pedido.id] = !showPedido[pedido.id]" class="cursor-pointer flex-none">
							<div v-if="!showPedido[pedido.id]">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
								</svg>
							</div>
							<div class="h-6 w-6" v-if="showPedido[pedido.id]">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
								</svg>
							</div>
						</div>
						</div>							
					</div>
					
					<div class="flex flex-row justify-between">	
						<router-link v-if="fields.cliente" :to=' getLinks(pedido).cliente ' class="underline cursor-pointer truncate w-full">
							{{pedido.cliente.nombre}} 
						</router-link>		
						<div v-if="fields.proveedor" class="truncate w-full">
							{{pedido.proveedor ? pedido.proveedor.nombre : "No tiene proveedor"}} 
						</div>

						<div v-if="fields.cliente || fields.proveedor" @click="showPedido[pedido.id] == null ? showPedido[pedido.id] = true : showPedido[pedido.id] = !showPedido[pedido.id]" class="cursor-pointer flex-none">
							<div v-if="!showPedido[pedido.id]">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
								</svg>
							</div>
							<div class="h-6 w-6" v-if="showPedido[pedido.id]">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
								</svg>
							</div>
						</div>
					</div>
				</div>				

				<div v-if="showPedido[pedido.id] && pedido.proveedor.standalone && !pedido.standalone">					
					<div class="border border-gray-300 text-black rounded mt-2">
						<p class="py-2 text-center">		
							Este pedido está surtido por PickPack
						</p>
					</div>
				</div>
        
				<div v-if="showPedido[pedido.id] && !pedido.proveedor.standalone && pedido.standalone">					
					<div class="border border-gray-300 text-black rounded mt-2">
						<p class="py-2 text-center">		
							Este pedido no está surtido por PickPack
						</p>
					</div>
				</div>

				<div v-if="showPedido[pedido.id] && pedido.instrucciones" class="w-full mt-2">
					<label class="block text-gray-400 text-sm font" for="nombre">
						Instrucciones
					</label>
					<div>
						<p class="">{{ pedido.instrucciones }}</p>
					</div>
				</div>

				<div v-if="showPedido[pedido.id]" class="overflow-x-hidden">
					<div class="w-full pt-0 pt-2 overflow-x-scroll">						
						<div class="rounded">
							<table class="text-sm text-left bg-background-light w-full">
								<thead class="text-s border-b border-b-gray-300 align-top">
									<th scope="col" class="py-3 px-4 w-1/4">
										Producto
									</th>
									<th scope="col" class="py-3 px-4 w-1/4">
										Cantidad 
									</th>								
									<th v-if="user !='admin' " scope="col" class="py-3 px-4 w-1/4">
										Precio
									</th>
									<th v-if="user !='admin' " scope="col" class="py-3 px-4 w-1/4">
										Total
									</th>
								</thead>
								<tbody>
									<tr class="text-s " v-for="line_item in pedido.line_items" v-bind:key="line_item.id">
										<td class="py-3 px-4 whitespace-nowrap">
											{{line_item.producto.nombre}}
										</td>
										<td class="py-3 px-4">
											{{line_item.cantidad}}
										</td>									
										<td v-if="user !='admin' " class="py-3 px-4">
											${{line_item.precio}}
										</td>
										<td v-if="user !='admin' " class="py-3 px-4">
											${{line_item.precio * line_item.cantidad}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>	

					<div v-if="showPedido[pedido.id] && (user == 'admin' || pedido.standalone == true) && !pedido.cancelado">
						<div class="pt-2">
							<router-link :to="`/admin/orden/${pedido.id}/remision`">
								<button  class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
									Ver remisión
								</button>				
							</router-link>				
						</div>
					</div>

					<div v-if="showPedido[pedido.id] && (user == 'admin') && pedido.require_factura && pedido.pedido_factura.length > 0">
						<div class="pt-2">
							<button  class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="openFacturas(pedido)">
								Ver facturas
							</button>								
						</div>
					</div>

					<div v-if="showPedido[pedido.id] && (user == 'admin')">
						<div class="pt-2">
							<router-link :to="`/admin/orden/${pedido.id}/remision`">
								<button  class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
									Ver facturas
								</button>				
							</router-link>				
						</div>
					</div>

					<div v-if="showPedido[pedido.id] && pedido.standalone == true && !pedido.entregado && !pedido.cancelado">
						<div class="pt-2">
							<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="entregarPedido(pedido)">
								Entregar
							</button>
						</div>
					</div>

					<div v-if="showPedido[pedido.id] && user == 'admin' && requiereSurtir(pedido)">
						<div class="pt-2">
							<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="surtirPedido(pedido)">
								Surtir
							</button>
						</div>
					</div>

					<div v-if="showPedido[pedido.id] && (user == 'admin' || pedido.standalone == true) && canBeCancelled(pedido)">
						<div class="pt-2">
							<button class="w-full bg-red hover:bg-red-dark rounded text-white font-bold py-2 px-4" @click="cancelarPedido(pedido)">
								Cancelar
							</button>
						</div>
					</div>

					<div v-if="showPedido[pedido.id] &&  user == 'admin' && pedido.surtido && pedido.require_entrega && !pedido.entregado" class=" pt-2">					
						<div v-if="pedido.envios.length > 0" class="w-full overflow-x-scroll">
							<div class="rounded bg-background-light">
								<table class="w-full text-sm text-left ">
									<thead class="text-s border-b border-b-gray-300 align-top">
										<th scope="col" class="py-3 px-4">
											Envio
										</th>
										<th scope="col" class="py-3 px-4">
											Tipo
										</th>
										<th scope="col" class="py-3 px-4">
											Estatus 
										</th>
										<th scope="col" class="py-3 px-4">
											Evidencia 
										</th>
										<th scope="col" class="py-3 px-4">

										</th>
									</thead>
									<tbody v-for="envio in pedido.envios" v-bind:key="envio.id">
										<tr class="text-s">
											<td class="py-2 px-4 whitespace-nowrap">
												{{envio.id}}
											</td>
											<td class="py-2 px-4">
												{{utils.upperCase(envio.type)}}
											</td>													
											<td class="py-2 px-4">												
												<div :class="[getStatusEnvio(envio).style]" class="rounded-full text-center py-1 px-2 mr-2">
													<p class="whitespace-nowrap">{{utils.upperCase(envio.status)}}</p>
												</div>
											</td>
											<td class="py-2 px-4">																							
												<div v-if=" envio.status != 'cancelado' " :class="[( (envio.evidencia && envio.evidencia.length > 0) ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500')]" class="rounded-full text-center py-1 px-2">
													<p class="whitespace-nowrap">{{ (envio.evidencia && envio.evidencia.length > 0) ? "Subido" : "Por subir"}}</p>
												</div>
											</td>
											<td @click="editEnvio[envio.id] == null ? editEnvio[envio.id] = true : editEnvio[envio.id] = !editEnvio[envio.id]" class="cursor-pointer pr-2">
												<div v-if="!editEnvio[envio.id]">
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
														<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
													</svg>
												</div>
												<div class="h-6 w-6" v-if="editEnvio[envio.id]">
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
														<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
													</svg>
												</div>
											</td>													
										</tr>	

										<tr v-if="editEnvio[envio.id] && (user == 'admin')"> 												
											<td colspan="99" class="">
												<div class="px-2 pb-2 cursor-pointer">
													<div class="w-full text-center rounded bg-primary hover:bg-primary-dark text-white font-bold">
														<label class="inline-block w-full py-2 px-4" :for="envio.id">Subir evidencia</label>
														<input
														class="hidden"
														type="file"
														:id="envio.id"
														accept="*az"
														@change="subirEvidencia(envio, $event)"
														/>
													</div>
												</div>

												<div class="flex justify-between px-2 pb-2">														
													<select class="w-2/3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap" v-if="editEnvio[envio.id]" v-model="envio.newStatus">
														<option selected disabled :value="undefined">Escoger un estatus</option>
														<option value="pendiente">Pendiente</option>			
														<option value="exitoso">Exitoso</option>			
														<option value="fallido">Fallido</option>			
														<option value="cancelado">Cancelado</option>			
													</select>

													<div class="w-1/3 text-left pl-4 flex-none">
														<button class="w-full rounded bg-primary hover:bg-primary-dark text-white font-bold p-2 px-4"  @click="updateEnvio(envio, {status: envio.newStatus});envio.newStatus = undefined">
															Actualizar 
														</button>
													</div>
												</div>										
												<div class="flex flex-row w-full px-2 pb-2">
													<div class="w-2/3 flex flex-row">
														<input class="w-1/2 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="nombre" type="number" placeholder="0" min="0" v-model="envio.cargo_extra">
														<select class="w-1/2 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap ml-4" v-if="editEnvio[envio.id]" v-model="envio.cargo_extra_type">
															<option selected disabled :value="null">Escoger un tipo</option>
															<option value="express">Express</option>			
															<option value="materiales">Materiales</option>
															<option value="peso">Peso</option>	
															<option value="peso_express">Peso y Express</option>	
															<!-- <option value="">Quitar</option>															 -->
														</select>
													</div>
													<div class="w-1/3 pl-4">
														<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="agregarCargoExtra(envio)">
															Agregar cargo
														</button>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					
						<div v-if="(user == 'admin') && pedido.surtido && pedido.require_entrega && !pedido.entregado" class="pt-2">
							<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="crearEnvio(pedido)">
								Crear envio
							</button>
						</div>					
					</div>		
				</div> 
			</div>
		</div>
		<div class="hidden sm:block mx-4 border border-gray-200 rounded bg-white">
			<div class="overflow-scroll">
				<table class="w-full text-sm text-left ">
					<thead class="text-s border-b border-b-gray-200">
						<tr>
							<th v-if="fields.fuente" scope="col" class="py-3 pr-4 pl-4">
								Fuente
							</th>
							<th scope="col" class="py-3 pr-4" :class=" fields.fuente ? '' : 'pl-4' ">
								Estado
							</th> 
							<th scope="col" class="py-3 pr-4">
								Orden
							</th>
							<th scope="col" class="py-3 pr-4">
								Fecha
							</th>
							<th v-if="fields.cliente" scope="col" class="py-3 pr-4">
								Cliente
							</th>	
							<th v-if="fields.proveedor" scope="col" class="py-3 pr-4">
								Proveedor
							</th>				
							<th scope="col" class="py-3 pr-4" v-if="fields.aprobar">
								Aprobación
							</th>								
							<th scope="col" class="py-3 pr-4" v-if="fields.surtir">
								Surtido
							</th>

							<th scope="col" class="py-3 pr-4" v-if="fields.facturar">
								Factura
							</th>
							<th scope="col" class="py-3 pr-4" v-if="fields.entregar">
								Entrega
							</th>
							<th scope="col" class="py-3 pr-4" v-if="fields.evidencia">
								Evidencia
							</th>	
							<th scope="col" class="py-3 pr-4" v-if="fields.pago">
								Pago
							</th>					
							<th class="py-3 pr-2">
							</th>				
						</tr>
					</thead>
					<tbody v-for="pedido in ordenes" v-bind:key="pedido.id">
						<tr class="text-s border-t">							
							<td v-if="fields.fuente" scope="row" class="pl-4 pr-4 font-medium whitespace-nowrap">
								<div class="border text-primary border-primary rounded-full text-center w-auto py-1 px-2">
									<p class="whitespace-nowrap">{{pedido.ecom ? "B2C" : "B2B"}}</p>
								</div>
							</td>

							<td class="pr-4" :class=" fields.fuente ? '' : 'pl-4' ">
								<div :class="[getStatus(pedido).style]" class="rounded-full text-center w-auto py-1 px-2">
									<p class="whitespace-nowrap">{{ getStatus(pedido).text }}</p>
								</div>
							</td>

							<td scope="row" class="py-4 pr-4 font-medium whitespace-nowrap underline cursor-pointer">
								<router-link :to=' getLinks(pedido).pedido '>
									{{pedido.id}}
								</router-link>
							</td>
							<td class="py-3 pr-4 whitespace-nowrap">
								{{(user == 'admin') ? formatTime(pedido) : (pedido.created_at ? (new Date(pedido.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' }) : "") }}
							</td>
							<td v-if="fields.cliente" class="py-3 pr-6 whitespace-nowrap underline cursor-pointer">
								<router-link :to=' getLinks(pedido).cliente '>
									{{pedido.cliente.nombre}} 
								</router-link>
							</td>
							<td v-if="fields.proveedor" class="py-3 pr-6 whitespace-nowrap">{{pedido.proveedor ? pedido.proveedor.nombre : "No tiene proveedor"}} 
							</td>
							<td v-if="fields.aprobar" class="pr-4">
								<div v-if="!pedido.cancelado" :class="[pedido.require_aprobacion ? ( pedido.aprobado ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center w-auto py-1 px-2">
									<p class="whitespace-nowrap">{{ pedido.require_aprobacion ? ( pedido.aprobado ? "Aprobado" : "Por abrobar") : "No requiere"}}</p>
								</div>
							</td>						
							<td v-if="fields.surtir" class="pr-4">							
								<surtir :pedido="pedido" :user="props.user"></surtir>
							</td>
							<td v-if="fields.facturar" class="pr-4">						
								<div v-if="!pedido.cancelado" :class="[pedido.require_factura ? ( pedido.pedido_factura.length > 0 ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center w-auto py-1 px-2">
									<p class="whitespace-nowrap">{{ pedido.require_factura ? ( pedido.pedido_factura.length > 0 ? "Facturado" : "Por subir") : "No requiere"}}</p>
								</div>

							</td>
							<td v-if="fields.entregar" class="pr-4">
								<entregar :pedido="pedido"></entregar>						
							</td>
							
							<td v-if="fields.evidencia" class="pr-4">
								<div :class="[pedido.require_entrega ? ( hasPendingEvidence(pedido) ? 'border border-yellow-500 text-yellow-500' : 'border border-primary text-primary') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
									<p class="whitespace-nowrap">{{ pedido.require_entrega ? ( hasPendingEvidence(pedido) ? "Pendiente" : "Evidencia") : "No requiere"}}</p>
								</div>
							</td>

							<td v-if="fields.pago" class="pr-4">
								<div v-if="pedido.proveedor.payments ">
									<div :class="[pedido.require_factura ? (pedido.pagado ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500' ) : 'border text-gray-300 border-gray-300' ]" class="rounded-full text-center py-1 px-2">
										<p class="whitespace-nowrap">{{ pedido.require_factura ? ( pedido.pagado ? "Pagado" : "Pendiente") : "No requiere"}}</p>
									</div>
								</div>
							</td>

							<td @click="showPedido[pedido.id] == null ? showPedido[pedido.id] = true : showPedido[pedido.id] = !showPedido[pedido.id]" class="cursor-pointer pr-4">
								<div class="w-full flex flex-row-reverse">
									<div v-if="!showPedido[pedido.id]">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
											<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
										</svg>
									</div>
									<div class="h-6 w-6" v-if="showPedido[pedido.id]">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
											<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
										</svg>
									</div>
								</div>
							</td>
						</tr>   

						<tr v-if="showPedido[pedido.id]">
							<td colspan="99" class="pb-4">
								<div class="w-full px-4 mb-2" v-if="pedido.proveedor.standalone && !pedido.standalone && user != 'cliente'">
									<div class="border border-gray-300 text-black rounded">
										<p class="py-2 text-center">		
											Este pedido está surtido por PickPack
										</p>
									</div>
								</div>

								<div class="w-full px-4 mb-2" v-if="!pedido.proveedor.standalone && pedido.standalone && user != 'cliente'">
									<div class="border border-gray-300 text-black rounded">
										<p class="py-2 text-center">		
											Este pedido no está surtido por PickPack
										</p>
									</div>
								</div>

								<div class="mb-2" v-if="pedido.instrucciones">
									<label class="block text-gray-400 text-sm font px-4" >
										Instrucciones
									</label>
									<div>
										<p class="px-4 mb-2">{{ pedido.instrucciones }}</p>
									</div>
								</div>

								<div class="w-full px-4">
									<div class="rounded bg-background-light">
										<table class="w-full text-sm text-left ">
											<thead class="text-s border-b border-b-gray-300 align-top">
												<th scope="col" class="py-3 px-6 w-1/4">
													Producto
												</th>
												<th scope="col" class="py-3 px-6 w-1/4">
													Cantidad 
												</th>
												<th v-if="user !='admin' " scope="col" class="py-3 px-6 w-1/4">
													Precio
												</th>
												<th v-if="user !='admin' " scope="col" class="py-3 px-6 w-1/4">
													Total
												</th>
											</thead>
											<tbody  v-for="line_item in pedido.line_items" v-bind:key="line_item.id" >
												<tr class="text-s " :class="[ pedido.envios.indexOf(envio) == pedido.envios.length ? '' : 'border-t border-t-gray-300']">
													<td class="py-3 px-6 whitespace-nowrap">
														{{line_item.producto.nombre}}
													</td>
													<td class="py-3 px-6">
														{{line_item.cantidad}}
													</td>													
													<td v-if="user !='admin' " class="py-3 px-6">
														${{line_item.precio}}
													</td>
													<td v-if="user !='admin' " class="py-3 px-6">
														${{line_item.precio * line_item.cantidad}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>

								<div class="px-4 mt-2 space-y-2" v-if="hasAction(pedido)">									
									<div class=" " v-if="(user == 'admin' || pedido.standalone == true) && !pedido.cancelado">
										<router-link :to="`/admin/orden/${pedido.id}/remision`">
											<button  class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
												Ver remisión
											</button>				
										</router-link>				
									</div>

									<div class="" v-if="(user == 'admin') && pedido.require_factura && pedido.pedido_factura.length > 0">
										<button  class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="openFacturas(pedido)">
											Ver facturas
										</button>								
									</div>

									<div class="" v-if="user == 'admin' && requiereSurtir(pedido)">
										<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="surtirPedido(pedido)">
											Surtir
										</button>
									</div>

									<div class="" v-if="pedido.standalone && !pedido.entregado && !pedido.cancelado">
										<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="entregarPedido(pedido)">
											Entregar
										</button>
									</div>

									<div class="" v-if="(user == 'admin' || pedido.standalone == true) && canBeCancelled(pedido)">
										<button class="w-full bg-red hover:bg-red-dark rounded text-white font-bold py-2 px-4" @click="cancelarPedido(pedido)">
											Cancelar
										</button>
									</div>

									<div class="" v-if="(user == 'admin' || pedido.standalone == true) && pedido.envios.length > 0">
										<div class="rounded bg-background-light">
											<table class="w-full text-sm text-left ">
												<thead class="text-s border-b border-b-gray-300 align-top">
													<th scope="col" class="py-3 px-6">
														Envio
													</th>
													<th scope="col" class="py-3 px-6">
														Tipo
													</th>												
													<th scope="col" class="py-3 px-6">
														Estatus 
													</th>
													<th scope="col" class="py-3 px-6">
														Evidencia 
													</th>
													<th scope="col" class="py-3 px-6 w-6">

													</th>
												</thead>
												<tbody v-for="envio in pedido.envios" v-bind:key="envio.id" class="">
													<tr class="text-s" :class="[ pedido.envios.indexOf(envio) == pedido.envios.length ? '' : 'border-t border-t-gray-300']">												
														<td class="py-2 px-6 whitespace-nowrap">
															{{envio.id}}
														</td>
														<td class="py-2 px-6">
															{{utils.upperCase(envio.type)}}
														</td>													
														<td class="py-2 px-6">														
															<div :class="[getStatusEnvio(envio).style]" class="rounded-full text-center py-1 px-2 mr-2">
																<p class="whitespace-nowrap">{{utils.upperCase(envio.status)}}</p>
															</div>
														</td>
														<td class="py-2 px-6">
															<div v-if=" envio.status != 'cancelado' " :class="[( (envio.evidencia && envio.evidencia.length > 0) ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500')]" class="rounded-full text-center py-1 px-2">
																<p class="whitespace-nowrap">{{ (envio.evidencia && envio.evidencia.length > 0) ? "Subido" : "Por subir"}}</p>
															</div>
														</td>
														<td @click="editEnvio[envio.id] == null ? editEnvio[envio.id] = true : editEnvio[envio.id] = !editEnvio[envio.id]" class="cursor-pointer pr-2">
															<div v-if="!editEnvio[envio.id]">
																<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
																	<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
																</svg>
															</div>
															<div class="h-6 w-6" v-if="editEnvio[envio.id]">
																<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
																	<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
																</svg>
															</div>
														</td>													
													</tr>	
													<tr v-if="editEnvio[envio.id] && (user == 'admin')"> 													
														<td colspan="99">
															<div class="px-2 pb-2 cursor-pointer">
																<div class="w-full text-center rounded bg-primary hover:bg-primary-dark text-white font-bold">
																	<label class="inline-block w-full py-2 px-4" :for="envio.id">Subir evidencia</label>
																	<input
																	class="hidden"
																	type="file"
																	:id="envio.id"
																	accept="*az"
																	@change="subirEvidencia(envio, $event)"
																	/>
																</div>
															</div>

															<div class="flex justify-between px-2 pb-2">														
																<select class="w-2/3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap" v-if="editEnvio[envio.id]" v-model="envio.newStatus">
																	<option selected disabled :value="undefined">Escoger un estatus</option>
																	<option value="pendiente">Pendiente</option>			
																	<option value="exitoso">Exitoso</option>			
																	<option value="fallido">Fallido</option>			
																	<option value="cancelado">Cancelado</option>			
																</select>

																<div class="w-1/3 text-left pl-4 flex-none">
																	<button class="w-full rounded bg-primary hover:bg-primary-dark text-white font-bold p-2 px-4"  @click="updateEnvio(envio, {status: envio.newStatus});envio.newStatus = undefined">
																		Actualizar 
																	</button>
																</div>
															</div>

															<div class="flex flex-row w-full px-2 pb-2">
																<div class="w-2/3 flex flex-row">
																	<input class="w-1/2 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="nombre" type="number" placeholder="0" min="0" v-model="envio.cargo_extra">
																	<select class="w-1/2 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap ml-4" v-if="editEnvio[envio.id]" v-model="envio.cargo_extra_type">
																		<option selected disabled :value="null">Escoger un tipo</option>
																		<option value="express">Express</option>			
																		<option value="materiales">Materiales</option>	
																		<option value="peso">Peso</option>	
																		<option value="peso_express">Peso y Express</option>
																		<!-- <option value="">Quitar</option>															 -->
																	</select>
																</div>
																<div class="w-1/3 pl-4">
																	<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="agregarCargoExtra(envio)">
																		Agregar cargo extra
																	</button>
																</div>
															</div>														
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="" v-if="(user == 'admin') && pedido.surtido && pedido.require_entrega && !pedido.entregado">
										<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="crearEnvio(pedido)">
											Crear envío
										</button>
									</div>
								</div>
							</td>	
						</tr>					
					</tbody>
				</table>
			</div>		
		</div>	
	</div>
</template>

<script>
	import { ref } from "vue"
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from '../utils/utils.js'
	import { useToast } from 'vue-toast-notification'
	import axios from "axios";

	export default {
		props: ['ordenes', 'fields', 'user'],		

		setup(props) {			
			const showPedido = ref({})	
			const editEnvio = ref({})			

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			function getLinks(pedido) {	
				switch (props.user) {	
				case "admin":	
					return {
						pedido: `/admin/orden/${pedido.id}`,
						cliente: `/admin/cliente/${pedido.cliente.id}`
					}									
				case "proveedor":
					return {
						pedido: `/proveedor/${pedido.proveedor.id}/orden/${pedido.id}`,
						cliente: `/proveedor/${pedido.proveedor.id}/cliente/${pedido.cliente.id}`
					}

				case "cliente":
					return {
						pedido: `/cliente/${pedido.cliente.id}/orden/${pedido.id}`,
						cliente: `/cliente/`
					}					
				}
			}

			// async function updatePedido(pedido, data) {
			// 	let key = Object.keys(data)[0]

			// 	let text;

			// 	switch (key) {	
			// 	case "entregado":	
			// 		text = "¿Quieres marcar este pedido como entregado?"
			// 		break				
			// 	}

			// 	if(confirm(text)){		
			// 		updatePedidoWithoutConfirm(pedido, data)					
			// 	}
			// }

			async function updateEnvio(envio, data) {
				let key = Object.keys(data)[0]

				let text;

				switch (key) {	
				case "status":	
					text = `¿Quieres marcar este envio cómo ${data[key]}?`
					break				
				}

				if(confirm(text)){		
					updateEnvioWithoutConfirm(envio, data)					
				}
			}

			async function updatePedidoWithoutConfirm(pedido, data) {
				let key = Object.keys(data)[0]				
				let loader = loading.show()			

				utils.updatePedido(pedido.id, data)
				.then( () => {
					loader.hide()
					pedido[key] = data[key]
					useToast().success('Orden actualizado', { position: "top-right", duration: 5000 });									
				})
				.catch( () => {
					loader.hide()
					console.log('Hubo un error, favor de intentarlo otra vez.')
				})
			}

			async function updateEnvioWithoutConfirm(envio, data) {
				let key = Object.keys(data)[0]				
				let loader = loading.show()			

				utils.updateEnvio(envio.id, data)
				.then( () => {
					loader.hide()
					envio[key] = data[key]
					useToast().success('Envio actualizado', { position: "top-right", duration: 5000 });									
				})
				.catch( () => {
					loader.hide()
					console.log('Hubo un error, favor de intentarlo otra vez.')
				})
			}

			function entregarPedido(pedido) {
				if(confirm("¿Quieres marcar este pedido como entregado?")){		
					let loader = loading.show()
							
					updatePedidoWithoutConfirm(pedido, { entregado: true })					
					.then( () => {		
						loader.hide()		
						pedido.entregado = true
						useToast().success('Pedido entregado', { position: "top-right" });
					})
					.catch( error => {					
						loader.hide()
						throw error
					})
				}
			}

			async function crearEnvio(pedido) {	
				if(confirm("¿Estas seguro que quieres crear una entrega?")){							
					let loader = loading.show()

					if (pedido.type == "retiro") {
						try {
							let envio = await utils.crearEnvio(pedido)
							pedido.envios.push(envio)
							loader.hide()
							useToast().success('Envio creado', { position: "top-right" });
							return
						} catch (e) {
							loader.hide()
							alert("Hubo un error, favor de intentarlo otra vez.")
							return
						}
					}

					try {
						let envio = await utils.crearEnvio(pedido)
						pedido.envios.push(envio)

						let direccion = {
							"number": envio.direccion.exterior,
							"street": envio.direccion.calle,
							"city": envio.direccion.colonia,
							"postalCode": envio.direccion.codigo,
							"state": "Ciudad de Mexico",
							"country": "Mexico"
						}

						if (envio.direccion.interior) {
							direccion.apartment = envio.direccion.interior					
						} 

						let horario = Object.keys(envio.horario).map( day => {
							if (envio.horario[day].open  == false) {
								return `${day}: Cerrado`
							} 

							return `${day}: ${envio.horario[day].start || "?"} a ${envio.horario[day].end  || "?"}`
						})

						let task = {
							"notes": `PickPack - ${horario.join(', ')}`,
							"overrides": { 
								"recipientName": "Override Name"
							},
							"destination": {
								"address": direccion
							},
							"metadata": [{
								"name": "load",
								"type": "object",
								"value": {
									"platform": "PickPack",
									"envioId": envio.id
								}
							}],
							"recipients": [{
								"name": `${envio.recipiente} - ${envio.contacto.nombre}`,
								"phone": envio.contacto.telefono
							}],
							"pickupTask": false,
						}			

						const headers = { 
							'Content-Type': 'application/json',
							'Authorization': 'Basic NTIxOTg5MTdkMDdkNWVlMzdkMmYwMTE2Yzc5MmZmYTg='
						};
						axios.post("https://onfleet.com/api/v2/tasks", task, { headers })
						.then( () => {
							loader.hide()
							useToast().success('Envio creado', { position: "top-right" });
						})
						.catch( e => {
							loader.hide()
							console.log(e)
							alert("Hubo un error, favor de intentarlo otra vez.")
						})
					} catch (e) {
						loader.hide()
						console.log(e)
						alert('Hubo un error, favor de intentarlo otra vez')
					}
				}
			}

			function surtirPedido(pedido) {
				if(confirm("¿Quieres surtir este pedido")){		
					let loader = loading.show()
					utils.updateInventory(pedido.line_items)		
					.then( () => {
						return updatePedidoWithoutConfirm(pedido, { surtido: true })					
					})
					.then( () => {		
						loader.hide()		
						pedido.surtido = true
						// useToast().success('Pedido surtido', { position: "top-right" });

					})
					.catch( error => {					
						loader.hide()
						throw error
					})
				}
			}															

			const subirEvidencia = async (envio, evt) => {	
				let loader = loading.show()				
				let urlToAdd;

				uploadFile(evt)
				.then( fileName => {					
					return getURL(fileName)
				})
				.then( url => {			
					urlToAdd = url
					if (!envio.evidencia) {						
						return updateEnvioWithoutConfirm(envio, {evidencia: [url]} )
					} else {												
						return updateEnvioWithoutConfirm(envio, {evidencia: envio.evidencia.push(url)} )	
					}					
				})
				.then( () => {	
					loader.hide()
					if (!envio.evidencia) {
						envio.evidencia = [urlToAdd]												
					} else {						
						envio.evidencia = envio.evidencia.push(urlToAdd)						
					}
					useToast().success('Evidencia subida', { position: "top-right" });					
				})
				.catch( error => {					
					loader.hide()
					throw error
				})
			}

			async function uploadFile(evt) {									
				if (!evt.target.files || evt.target.files.length === 0) {
					throw new Error("Selecciona un archivo")
				}
				const file = evt.target.files[0]
				const fileExt = file.name.split(".").pop()
				const fileName = `${Math.random()}.${fileExt}`
				const filePath = `${fileName}`					

				return await utils.uploadFile('remisiones', filePath, file)	
			}

			async function getURL(file) {
				let fileOnly = file.split('remisiones/')[1]
				return await utils.getURL('remisiones', fileOnly)				
			}

			function getStatus(pedido) {				
				if (pedido.cancelado == true ) {
					return {
						style: "border border-gray-300 text-gray-300",
						text: "Cancelado"
					}
				} else if (utils.isPendiente(props.user, pedido)) {
					return {
						style: "bg-yellow-500 text-white",
						text: "Pendiente"
					}
				} else {
					return {
						style: "bg-primary text-white",
						text: "Completo"
					}
				}
			}

			function getStatusEnvio(envio) {				
				if (envio.status == 'cancelado') {
					return {
						style: "border border-gray-300 text-gray-300",
						text: "Cancelado"
					}
				} else if (envio.status == "pendiente") {
					return {
						style: "bg-yellow-500 text-white",
						text: "Pendiente"
					}
				} else if (envio.status == "fallido") {
					return {
						style: "bg-red-500 text-white",
						text: "Fallido"
					}
				} else {
					return {
						style: "bg-primary text-white",
						text: "Exitoso"
					}
				}
			}

			function formatTime(pedido) {
				let date = new Date(pedido.created_at).toLocaleString('en-GB', { timeZone: 'America/Mexico_City' })
				return `${date.split(":")[0]}:${date.split(":")[1]}`
			}

			function requiereSurtir(pedido) {
				if (pedido.surtido) {
					return false
				}

				return utils.requireEntregaSurtir(pedido).surtir
			}

			function canBeCancelled(pedido) {
				if (pedido.standalone && !pedido.cancelado) {
					return !pedido.entregado
				}

				return !pedido.cancelado && pedido.envios.filter(e => e.status == 'exitoso').length == 0
			}

			function cancelarPedido(pedido) {
				if(confirm("¿Quieres cancelar este pedido")){		
					let loader = loading.show()
					
					let data = { cancelado: true }
					
					updatePedidoWithoutConfirm(pedido, data)					
					.then( () => {
						if (pedido.surtido) {
							console.log('Returning stock')
							let canceled_line_items = pedido.line_items.map( li => {
								let new_li =  Object.assign({}, li);
								new_li.cantidad = new_li.cantidad * -1
								return new_li
							})

							return utils.updateInventory(canceled_line_items)
						} else {
							return
						}
					})	
					.then( () => {		
						loader.hide()
						pedido.cancelado = true						
						useToast().success('Pedido cancelado', { position: "top-right" });

					})									
					.catch( error => {					
						loader.hide()
						throw error
					})
				}
			}

			function agregarCargoExtra(envio) {				
				if (envio.cargo_extra && !envio.cargo_extra_type) {
					return alert('Favor de agregar una descripción del cargo extra')
				}

				if(confirm("¿Quieres agregar un cargo extra a este pedido")){		
					let loader = loading.show()
					let data = { cargo_extra: envio.cargo_extra ? envio.cargo_extra : null, cargo_extra_type: envio.cargo_extra_type ? envio.cargo_extra_type : null }
					console.log(data)
					updateEnvioWithoutConfirm(envio, data)
					.then( () => {		
						loader.hide()						
						useToast().success('Envio actualizado', { position: "top-right" });

					})
					.catch( error => {					
						loader.hide()
						throw error
					})
				}
			}

			function openFacturas(pedido) {
				pedido.pedido_factura.forEach( pF => {
					window.open(pF.url)	
				})	    
			}

			function hasPendingEvidence(pedido) {
				if (pedido.require_entrega && (!pedido.envios || pedido.envios.length == 0)) {
					return true
				}

				if (pedido.require_entrega && pedido.envios.filter(e => e.status == 'pendiente').length > 0) {
					return true
				}

				// console.log(pedido.id, pedido.envios.filter(e => (e.status == 'exitoso' || e.status == 'fallido') && (!e.evidencia || e.evidencia.length == 0)).length > 0, pedido)
				return pedido.envios.filter(e => (e.status == 'exitoso' || e.status == 'fallido') && (!e.evidencia || e.evidencia.length == 0)).length > 0
			}

			function hasAction(pedido) {
				if ((props.user == 'admin' || pedido.standalone == true) && !pedido.cancelado) {
					return true
				}

				if (props.user == 'admin' && pedido.require_factura && pedido.pedido_factura.length > 0) {
					return true
				}	

				if (props.user == 'admin' && requiereSurtir(pedido)) {
					return true
				}

				if (pedido.standalone && !pedido.entregado && !pedido.cancelado) {
					return true
				}

				if ((props.user == 'admin' || pedido.standalone == true) && canBeCancelled(pedido)) {
					return true
				}

				if ((props.user == 'admin' || pedido.standalone == true) && pedido.envios.length > 0) {
					return true
				}

				if (props.user == 'admin' && pedido.surtido && pedido.require_entrega && !pedido.entregado) {
					return true
				}

				return false				
			}	

			return {      
				getStatus,
				showPedido,
				editEnvio,
				requiereSurtir,
				surtirPedido,
				entregarPedido,
				crearEnvio,
				subirEvidencia,
				formatTime,
				getLinks,
				props,
				openFacturas,
				utils,
				updateEnvio,
				agregarCargoExtra,
				getStatusEnvio,
				hasPendingEvidence,
				canBeCancelled,
				cancelarPedido,
				hasAction
			}
		}
	}
</script>