<template>
	<div class="flex flex-col h-full">		
		<div class="block lg:flex overflow-y-scroll">
			
			<div v-if="pedido && pedido.id" class="w-full lg:w-8/12 overflow-scroll">
				<div class="flex flex-row items-center pt-4 px-4 justify-between">
					<div class="flex content-center">
						<div @click="utils.goBack(router, admin ? '/admin/ordenes' : `/proveedor/${pedido.proveedor.id}/ordenes`)" class="pr-2 flex items-center cursor-pointer"> 				
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
							</svg>
						</div>	
						<h3 class="text-2xl font-bold">
							Orden #{{pedido.id}}
						</h3>
					</div>	
					<status :pedido="pedido" :user="'proveedor'"></status>
				</div>

				<div class="rounded border bg-white rounded m-4">
					<div class="flex p-4 pb-4 overflow-x-scroll">
						<div class="">
							<label class="block text-gray-400 text-sm font mb-0" for="nombre">
								Cliente
							</label>
							<h3 class="text-xl font-semibold">
								<router-link :to=' `/proveedor/${pedido.proveedor.id}/cliente/${pedido.cliente.id}` ' class="underline cursor-pointer truncate w-full">
									{{pedido.cliente.nombre}}
								</router-link>
							</h3>						
						</div>
						<div class="ml-4">
							<label class="block text-gray-400 text-sm font mb-0" for="nombre">
								Fecha
							</label>
							<h3 class="text-xl font-semibold">
								{{(new Date(pedido.created_at)).toLocaleDateString("en-GB")}}
							</h3>						
						</div>
					</div>

					<div class="flex flex-row px-4 pt-0 pb-4 overflow-x-scroll w-full" v-if="!pedido.cancelado">
						<div class="basis-3/12 w-full mr-4" v-if="!pedido.standalone">
							<label class="block text-gray-400 text-sm font mb-2" for="nombre">
								Aprobación
							</label>
							<div  :class="[pedido.require_aprobacion ? ( pedido.aprobado ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
								<p class="whitespace-nowrap">{{ pedido.require_aprobacion ? ( pedido.aprobado ? "Aprobado" : "Por abrobar") : "No requiere"}}</p>
							</div>
						</div>
						<div class="basis-3/12 w-full mr-4" v-if="!pedido.standalone">
							<label class="block text-gray-400 text-sm font mb-2" for="nombre">
								Surtido
							</label>
							<surtir :pedido="pedido" :user="'proveedor'"></surtir>
						</div>
						<div class="basis-3/12 w-full mr-4">
							<label class="block text-gray-400 text-sm font mb-2" for="nombre">
								Factura
							</label>
							<div :class="[pedido.require_factura ? ( pedido.pedido_factura.length > 0 ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
								<p class="whitespace-nowrap">{{ pedido.require_factura ? ( pedido.pedido_factura.length > 0 ? "Facturado" : "Subir factura") : "No requiere"}}</p>
							</div>

						</div>
						<div class="basis-3/12 w-full">
							<label class="block text-gray-400 text-sm font mb-2" for="nombre">
								Entrega
							</label>
							<entregar :pedido="pedido"></entregar>
						</div>

						<div class="basis-3/12 w-full ml-4" v-if="pedido.proveedor.payments">
							<label class="block text-gray-400 text-sm font mb-2" for="nombre">
								Pago
							</label>
							<div :class="[pedido.require_factura ? ( pedido.pagado ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
								<p class="whitespace-nowrap">{{ pedido.require_factura ? ( pedido.pagado ? "Pagado" : "Pendiente") : "No requiere"}}</p>
							</div>
						</div>
					</div>

					<div v-if="pedido.instrucciones" class="px-4 pb-4">				
						<label class="block text-gray-400 text-sm font" for="nombre">
							Instrucciones
						</label>
						<div>
							<p class="">{{ pedido.instrucciones }}</p>
						</div>
					</div>

					<div class="px-4 pb-4" v-if="!pedido.proveedor.standalone">				
						<label class="block text-gray-400 text-sm font" for="nombre">
							Dirección de entrega
						</label>
						<div>
							<p>{{pedido.datos_entrega.direccion.calle}} {{pedido.datos_entrega.direccion.exterior}}</p>						
							<p>{{pedido.datos_entrega.direccion.colonia}} {{pedido.datos_entrega.direccion.codigo}}</p>						
							<p>{{pedido.datos_entrega.direccion.estado}}</p>

						</div>
					</div>	
				</div>	

				<div v-if="pedido.proveedor.standalone && !pedido.standalone" class="border border-gray-200 text-black rounded cursor-pointer mt-2 bg-white mx-4">
					<p class="py-2 text-center">		
						Este pedido está surtido por PickPack
					</p>
				</div>		

				<div class="w-full text-left px-4 pb-0">
					<div class="border boder-gray-300 rounded mt-4 bg-white ">
						<div class="overflow-scroll">
							<table class="w-full text-sm text-left ">
								<thead class="text-s border-b border-b-gray-200">
									<th scope="col" class="py-3 px-6 w-3/12">
										Producto
									</th>
									<th scope="col" class="py-3 px-6 w-3/12">
										Cantidad
									</th>									
									<th scope="col" class="py-3 px-6 w-3/12">
										Precio
									</th>
									<th scope="col" class="py-3 px-6 w-3/12">
										Total
									</th>
								</thead>
								<tbody>
									<tr class="text-s border-b" v-for="line_item in pedido.line_items" v-bind:key="line_item.id">
										<td class="py-3 px-6 whitespace-nowrap">
											{{line_item.producto.nombre}}
										</td>
										<td class="py-3 px-6">
											{{line_item.cantidad}}
										</td>
										<td class="py-3 px-6">
											${{line_item.precio}}
										</td>
										<td class="py-3 px-6">
											${{line_item.precio * (line_item.cantidad || 0)}}
										</td>
									</tr>
								</tbody>
								<tfoot class="text-s text-gray-700 border-t dark:text-gray-400">									
									<tr>
										<th scope="col" class="py-3 px-6">
											Total
										</th>
										<th scope="col" class="py-3 px-6">
											{{pedido.line_items.map( l => {return (l.cantidad)} ).reduce((a, b) => a + b) }}
										</th>										
										<th scope="col" class="px-6 pt-3 pb-1 font-medium">

										</th>
										<th scope="col" class="py-3 px-6">
											${{pedido.line_items.map( l => {return l.precio * (l.cantidad || 0)} ).reduce((a, b) => a + b) + (pedido.envio || 0) }}
										</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<div v-if="pedido.envios.filter( e => e.status != 'cancelado').length > 0 && !pedido.standalone" class="w-full text-left px-4 pt-4">
					<div class="border boder-gray-300 rounded bg-white">
						<div class="overflow-scroll">
							<table class="w-full text-sm text-left">
								<thead class="text-s border-b border-b-gray-200">
									<th scope="col" class="py-3 px-6 w-3/12">
										{{ pedido.envios.length == 1 ? "Envío" : "Envíos"}}
									</th>	
									<th scope="col" class="py-3 px-6 w-3/12">
										Estatus
									</th>																
									<th scope="col" class="py-3 px-6 w-3/12">
										Costo
									</th>								
								</thead>
								<tbody v-for="envio in pedido.envios.filter( e => e.status != 'cancelado')" v-bind:key="envio.id">									
									<tr class="text-s dark:border-gray-700 border-b">
										<td class="py-3 px-6 whitespace-nowrap">
											{{envio.id}}
										</td>
										<td class="py-3 px-6">
											{{utils.upperCase(envio.status)}}
										</td>	
										<td v-if="envio.status != 'cancelado'" class="py-3 px-6">
											${{Math.round((envio.costo + (envio.cargo_extra || 0)) * 100)/100}}
										</td>																				
									</tr>									
								</tbody>
								<tfoot class="text-s text-gray-700 dark:text-gray-400">									
									<tr >
										<th scope="col" class="py-3 px-6">
											Total
										</th>	
										<th scope="col" class="py-3 px-6">
											
										</th>										
										<th scope="col" class="py-3 px-6">
											<!-- ${{Math.round(pedido.envios.filter( e => (e.status == 'exitoso' || e.status == 'fallido')).map( e => {return e.costo + (e.cargo_extra || 0)} ).reduce((a, b) => a + b) * 100) / 100 }} -->
											${{getEnviosTotal()}}
										</th>
									</tr>									
								</tfoot>
							</table>
						</div>
					</div>
				</div>

				<div v-if="getAttachments().length > 0" class="text-left p-4 pt-4 flex flex-row w-full overflow-scroll gap-8 pb-8">
					<div v-for="attachment in getAttachments()" v-bind:key="attachment.id" class="flex-none w-72 border boder-gray-300 rounded overflow-hidden bg-white">
						<div class="flex justify-between items-center"> 
							<div class="text-sm font-bold py-3 px-6">
								<a :href="attachment.link">{{attachment.type}}</a>
							</div>
							<svg v-if="attachment.type == 'Factura'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" @click="deleteFactura(attachment.pf)" class="w-6 h-6 mr-4 cursor-pointer">
								<path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
							</svg>

							<svg v-if="attachment.type == 'Evidencia' && pedido.standalone" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" @click="deleteEvidencia(attachment.link)" class="w-6 h-6 mr-4 cursor-pointer">
								<path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
							</svg>
						</div>
						<a :href="attachment.link" target="_blank"> <embed :src="attachment.link" class="w-72 h-full object-cover">
						</a>
					</div>
				</div>
			</div>	

			<div v-if="pedido" class="w-full lg:w-4/12 p-4 pl-4 lg:pl-0 pt-4 lg:pt-4">
				<h3 class="text-xl font-semibold">
					Acciones	
				</h3>				

				<div v-if="(admin || pedido.standalone) || !pedido.require_entrega" class="mt-4">
					<router-link :to="`/admin/orden/${pedido.id}/remision`">
						<button class="w-full bg-white border border-primary hover:border-primary-dark rounded text-primary hover:text-primary-dark font-bold py-2 px-4 ">
							Ver remisión
						</button>
					</router-link>
				</div>


				<div class="mt-4">
					<button  class="w-full bg-white border border-primary hover:border-primary-dark rounded text-primary hover:text-primary-dark font-bold py-2 px-4 " @click="copiarEnlace()">
						Copiar enlace
					</button>
				</div>

				<div v-if="pedido.proveedor.payments && !pedido.pagado" class="mt-4">
					<button  class="w-full bg-white border border-primary hover:border-primary-dark rounded text-primary hover:text-primary-dark font-bold py-2 px-4 " @click="copiarEnlace(true)">
						Copiar enlace de pago
					</button>
				</div>

				<div v-if="admin && pedido" class="border border-primary rounded flex flex-row bg-white mt-4 bg-white">
					<button @click="updatePedidoWithoutConfirm({require_entrega: false})" :class="[{'bg-primary hover:bg-primary-dark text-white': pedido.require_entrega === false, 'text-primary': pedido.require_entrega === true}]" class="w-full font-bold py-2 px-4" >
						Sin entrega
					</button>
					<button @click="updatePedidoWithoutConfirm({require_entrega: true})" :class="[{'bg-primary hover:bg-primary-dark text-white': pedido.require_entrega === true, 'text-primary': pedido.require_entrega === false}]" class="w-full font-bold py-2 px-4" >
						Con entrega
					</button>	
				</div>	

				<div v-if="pedido && !pedido.cancelado" class="border border-primary rounded flex flex-row bg-white mt-4 bg-white">
					<button @click="updatePedidoWithoutConfirm({require_factura: false})" :class="[{'bg-primary hover:bg-primary-dark text-white': pedido.require_factura === false, 'text-primary': pedido.require_factura === true}]" class="w-full font-bold py-2 px-4" >
						Sin factura
					</button>
					<button @click="updatePedidoWithoutConfirm({require_factura: true})" :class="[{'bg-primary hover:bg-primary-dark text-white': pedido.require_factura === true, 'text-primary': pedido.require_factura === false}]" class="w-full font-bold py-2 px-4" >
						Con factura
					</button>	
				</div>	

				<div v-if="!admin && pedido && pedido.require_factura && pedido.proveedor.payments && !pedido.standalone" class="border border-primary rounded flex flex-row bg-white mt-4 bg-white">
					<button @click="updatePedidoWithoutConfirm({pagado: false})" :class="[{'bg-primary hover:bg-primary-dark text-white': !pedido.pagado, 'text-primary': pedido.pagado }]" class="w-full font-bold py-2 px-4" >
						Pendiente
					</button>
					<button @click="updatePedidoWithoutConfirm({pagado: true})" :class="[{'bg-primary hover:bg-primary-dark text-white': pedido.pagado, 'text-primary': !pedido.pagado }]" class="w-full font-bold py-2 px-4" >
						Pagado
					</button>	
				</div>	


				<div v-if="requireAprobacion" class="mt-4">
					<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4 " @click="updatePedido({aprobado: true})">
						Aprobar pedido
					</button>
				</div>

				<div v-if="pedido && !pedido.cancelado && pedido.require_factura" class="mt-4">
					<label class="whitespace-nowrap cursor-pointer inline-block w-full border border-primary hover:bg-primary-dark text-white bg-primary font-bold text-center px-4 py-2 rounded" :for="pedido ? pedido.id : '1'">{{ pedido.pedido_factura.length == 0 ? "Subir factura" : "Subir factura adicional"}}</label>
					<input
					class="hidden"
					type="file"
					:id="pedido ? pedido.id : '1'"
					accept="application/pdf, .zip, .xml"
					@change="subirFactura(pedido, $event)"		
					/>
				</div>	

				<div class='w-full h-px bg-gray-300 mt-4' v-if="(pedido.standalone && !pedido.entregado) || puedeCancelar"> 
				</div>

				<div v-if="pedido.standalone && !pedido.entregado" class="mt-4">
					<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4 " @click="updatePedido({entregado: true})">
						Entregar
					</button>
				</div>

				<div v-if="pedido && pedido.entregado" class="mt-4">
					<label class="whitespace-nowrap cursor-pointer inline-block w-full border border-primary hover:bg-primary-dark text-white bg-primary font-bold text-center px-4 py-2 rounded" :for="999">Subir evidencia</label>
					<input
					class="hidden"
					type="file"
					:id="999"
					accept="image/*"
					@change="subirEvidencia(pedido, $event)"		
					/>
				</div>	


				<div v-if="pedido.standalone && !pedido.entregado && canBeSendWithPickPack()" class="mt-4">
					<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4 " @click="updatePedido({standalone: false})">
						Mandar con PickPack
					</button>
				</div>

				<div v-if="puedeCancelar" class="mt-4">
					<button class="whitespace-nowrap cursor-pointer inline-block w-full border border-red hover:bg-red-dark text-white bg-red font-bold text-center px-4 py-2 rounded " @click="updatePedido({cancelado: true})">
						Cancelar pedido
					</button>
				</div>
			</div>	
		</div>	
	</div>
</template>
<script>
	import { useRoute, useRouter } from "vue-router"
	import { computed, onMounted, ref } from "vue"
	import { useToast } from 'vue-toast-notification'
	import axios from "axios"
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from './utils/utils.js'

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()
			const router = useRouter()
			const pedido = ref(null)			
			const admin = ref(false)			

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			onMounted( async () => {
				// let loader = loading.show()
				utils.getPedido(route.params.orden_id)
				.then( data => {
					// loader.hide()
					pedido.value = data
				})

				if (route.name == "Admin_Orden") {
					admin.value = true
				}	
			})

			const evidencias = computed( () => {
				if (!pedido.value) {
					return
				}

				return pedido.value.envios.filter( e => e.status != 'cancelado').flatMap( e => {
					if (!e.evidencia) {
						return []
					}
					return e.evidencia.flatMap( ev => {
						return {
							envio: e.id,
							evidencia: ev
						}
					})
				})
			})

			const requireAprobacion = computed( () => {
				return pedido.value && pedido.value.require_aprobacion && !pedido.value.aprobado && pedido.value.cancelado == false
			})

			const puedeCancelar = computed( () => {
				if (pedido.value.standalone ) { //Allow standalone customer to cancel any order
					if (pedido.value.entregado)	{
						return false
					}
					return true
				} 

				return pedido.value && pedido.value.surtido != true && pedido.value.cancelado != true //The cliente can only cancel orders that haven't been fullfilled
			})	

			function copiarEnlace(payment) {
				if (pedido.value.ecom) {
					navigator.clipboard.writeText(`${window.location.host}/shop/pedido/${pedido.value.id}`);
					useToast().success('Enlace copiado', { position: "top-right" });
				} else {
					navigator.clipboard.writeText(`${window.location.host}/cliente/${pedido.value.cliente.id}/orden/${pedido.value.id}${ payment ? "?pago=true" : ""}`);
					useToast().success('Enlace copiado', { position: "top-right" });	
				}	
			}

			async function deleteFactura(pedidoFactura) {
				console.log(pedidoFactura)
				if (confirm("¿Quieres eliminar esta factura?")) {
					let loader = loading.show()
					utils.deletePedidoFactura(pedidoFactura)
					.then( () => {
						loader.hide()
						pedido.value.pedido_factura = pedido.value.pedido_factura.filter( pF => pF.id !== pedidoFactura.id )	
						useToast().success('Factura eliminads', { position: "top-right", duration: 5000 });									
					})
					.catch( error => {
						console.log(error)
						loader.hide()
						alert("Hubo un error, favor de intentarlo otra vez.")
					})
				} else {
					return
				}	
			}

			async function uploadFile(type, evt) {			
				if (!evt.target.files || evt.target.files.length === 0) {
					throw new Error("Selecciona un archivo")
				}
				const file = evt.target.files[0]
				const fileExt = file.name.split(".").pop()
				const fileName = `${Math.random()}.${fileExt}`
				const filePath = `${fileName}`

				return await utils.uploadFile(type, filePath, file)						
			}

			async function getURL(type, file) {
				let fileOnly = file.split(`${type}/`)[1]
				return await utils.getURL(type, fileOnly)				
			}

			async function updatePedidoWithoutConfirm(data) {
				let key = Object.keys(data)[0]
				let loader = loading.show()

				utils.updatePedido(pedido.value.id, data)
				.then( () => {
					loader.hide()
					pedido.value[key] = data[key]
					useToast().success('Orden actualizado', { position: "top-right", duration: 5000 });									
				})
				.catch( () => {
					loader.hide()
					console.log('Hubo un error, favor de intentarlo otra vez.')
				})
			}

			async function updatePedido(data) {
				let key = Object.keys(data)[0]

				let text;

				switch (key) {	
				case "cancelado":	
					text = "¿Quieres cancelar este orden?"
					break
				case "aprobado":
					text = "¿Quieres aprobar este orden?"
					break
				case "entregado":
					text = "¿Quieres marcar este orden como entregado?"
					break
				case "standalone":
					text = "¿Quieres que PickPack surte este pedido?"
					break
				}

				if(confirm(text)){		
					updatePedidoWithoutConfirm(data)					
				}
			}

			async function mandarFactura() {
				try {									
					axios
					.post('/api/mandar_factura', {
						orden_id: pedido.value.id
					})
					.finally( () => {
						return
					}) 	
				}
				catch (e) {
					console.log(e)
					return 
				}
			}

			async function deleteEvidencia(evidencia) {
				if (confirm("¿Quieres eliminar esta evidencia?")) {
					let loader = loading.show()
					let file = evidencia.split('evidencia/')[evidencia.split('evidencia/').length - 1]

					utils.deleteFile("evidencia", file)
					.then( () => {
						const index = pedido.value.evidencia.indexOf(evidencia);
						pedido.value.evidencia.splice(index, 1)

						if (pedido.value.evidencia.length == 0) {
							pedido.value.evidencia = null
						}
						
						return updatePedidoWithoutConfirm({evidencia: pedido.value.evidencia})
					})	
					.then( () => {					
						loader.hide()
						useToast().success('Evidencia eliminada', { position: "top-right" });											
					})
					.catch( e => {
						alert('Hubo un error, favor de intentarlo otra vez.')
						console.log(e)
					})
				}
				
			}

			const subirEvidencia = async (pedido, evt) => {
				let loader = loading.show()

				uploadFile("evidencia", evt)
				.then( fileName => {
					return getURL("evidencia", fileName)
				})
				.then( url => {
					console.log('URL', url)
					console.log("pedido.evidencia", pedido.evidencia)

					if (!pedido.evidencia) {
						pedido.evidencia = [url]
					} else {
						pedido.evidencia.push(url)
					}

					console.log("pedido.evidencia", pedido.evidencia)
					return updatePedidoWithoutConfirm({evidencia: pedido.evidencia})
				})
				.then( () => {					
					loader.hide()
					useToast().success('Evidencia subida', { position: "top-right" });											
				})
				.catch( (e) => {
					console.log(e)
					loader.hide()
					alert('Error al subir evidencia, favor de intertarlo otra vez.')
				})
			}				

			const subirFactura = async (pedido, evt) => {
				let loader = loading.show()

				uploadFile("facturas", evt)
				.then( fileName => {
					return getURL("facturas", fileName)
				})
				.then( url => {
					return utils.crearPedidoFactura(pedido.id, pedido.proveedor.id, url)
				})
				.then( (data) => {
					pedido.pedido_factura.push(data)
					loader.hide()
					useToast().success('Factura subida', { position: "top-right" });
					mandarFactura()								
				})
				.catch( () => {
					loader.hide()
					alert('Error al subir factura, favor de intertarlo otra vez.')
				})
			}			

			function canBeSendWithPickPack() {
				// let productos = await utils.getProductsById(pedido.value.line_items.map( l => l.producto.id ))
				
				// console.log("canBeSendWithPickPack", productos)

				let hasStock = pedido.value.line_items.map( l => {
					
					// let producto = productos.find( p => p.id == l.producto.id)
					// console.log('hasStock', producto, producto.cantidad > 0 && producto.cantidad >= l.cantidad)

					if (l.producto.cantidad > 0 && l.producto.cantidad >= l.cantidad) {
						return true
					}

					return false
				})

				console.log("hasStock", hasStock, !hasStock.includes(false))

				return !hasStock.includes(false)
			}

			function getEnviosTotal() {
				// ${{Math.round(pedido.envios.filter( e => (e.status == 'exitoso' || e.status == 'fallido')).map( e => {return e.costo + (e.cargo_extra || 0)} ).reduce((a, b) => a + b) * 100) / 100 }}

				let enviosPorCobrar = pedido.value.envios.filter( e => e.status != 'cancelado')

				if (enviosPorCobrar.length == 0) {
					return 0
				}

				return Math.round(enviosPorCobrar.map( e => {return e.costo + (e.cargo_extra || 0)} ).reduce((a, b) => a + b) * 100) / 100 
			}

			function getAttachments() {
				let attachments = []

				if (pedido.value && pedido.value.pedido_factura.length > 0) {
					attachments.push(pedido.value.pedido_factura.map( pf => {
						return {
							type: "Factura",						
							link: pf.url,
							pf: pf
						}
					}))
				}

				if (pedido.value && evidencias.value.length > 0) {
					attachments.push(evidencias.value.map( evidencia => {
						return {
							type: "Evidencia",
							link: evidencia.evidencia
						}
					}))
				}

				if (pedido.value && pedido.value.evidencia && pedido.value.evidencia.length > 0) {
					attachments.push(pedido.value.evidencia.map( evidencia => {
						return {
							type: "Evidencia",
							link: evidencia
						}
					}))
				}

				return attachments.flat()	
			}

			return {
				pedido,
				subirFactura,			
				requireAprobacion,
				updatePedido,
				puedeCancelar,
				deleteFactura,
				updatePedidoWithoutConfirm,
				copiarEnlace,
				route,
				router,
				admin,
				utils,
				getEnviosTotal,
				evidencias,
				canBeSendWithPickPack,
				getAttachments,
				subirEvidencia,
				deleteEvidencia
			}
		},
	}
</script>
